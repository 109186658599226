import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import cn from "classnames";

class Dialog extends Component {
  state = {
    hidden: !this.props.visible,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.onShow();
    } else if (prevProps.visible && !this.props.visible) {
      this.onHide();
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickOutside);
  }

  onShow = () => {
    document.body.addEventListener('click', this.handleClickOutside);
    setTimeout(() => {
      this.setState({
        hidden: false,
      });
      document.body.classList.add('modal-open');
    });
  };

  onHide = () => {
    document.body.removeEventListener('click', this.handleClickOutside);
    const hasAnotherModalOpen = document.querySelector('.modal.show');
    if (!hasAnotherModalOpen) document.body.classList.remove('modal-open');
    
  };

  handleClickOutside = e => {
    if (!e.target.classList.contains('modal')) return;
    this.handleClose(e);
  };

  handleClose = () => {
    // document.body.classList.remove('modal-open');
    if (this.props.visible && this.props.onClose) {
      this.props.onClose();
    }
    this.setState({
      hidden: true,
    });
  };

  render() {

    const {
      // modalRef,
      title,
      subtitle,
      children,
      visible,
      footer, 
      icon,
      footerClassName,
      onClose,
      iconRender,
      removeIcon
    } = this.props;
  
    return (
      <div aria-hidden="true" className={cn(`modal fade ${visible ? "show" : ""}`, { visible, hidden: this.state.hidden })} role="dialog">
        
        <div className={`modal-dialog modal-dialog-${this.props.size}`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button aria-label="Close" className="close" onClick={onClose} type="button">
                <i className="icon pe-7s-close-circle" />
              </button>
            </div>
            <div className="modal-body">
              {/* <i className={cn('icon', icon)} /> */}
              {!removeIcon && iconRender({ icon })}
              <h5 className="modal-title">{title}</h5>
              <div>{subtitle}</div>
              <div>{children}</div>
            </div>
            <div className={cn("modal-footer", footerClassName)}>{footer}</div>
          </div>
        </div>
        {/* <div className={`modal-question-backdrop ${visible ? "show" : ""}`} onClick={onClose} /> */}
      </div>
    );
  }
}

Dialog.propTypes = {
  children: PropTypes.node,
  // title: PropTypes.string,
  // subtitle: PropTypes.string,
  visible: PropTypes.bool,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  icon: PropTypes.string,
  footerClassName: PropTypes.string,
  iconRender: PropTypes.func,
};

Dialog.defaultProps = {
  children: null,
  title: null,
  subtitle: null,
  visible: false,
  iconRender: ({ icon, ...props }) => (icon ? <i className={cn("icon", icon)} /> : null),
  onClose: () => {},
  icon: "pe-7s-check",
  size: 'md'
};

export default Dialog;
