import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "../../components/Button";
import Loader from "../../components/Loader";
import PDFDownloadLink from "../../components/PDFDownloadLink";
import ReportPDF from "../../components/ReportPDF";
import Alert from "../../components/Alert";
import { getCountries } from "../../store/login";

import DetailsHeader from "./DetailsHeader";
import DetailsHitLevel from "./DetailsHitLevel";
import DetailsResultsByHitLevel from "./DetailsResultsByHitLevel";
// import DetailsResultsByHitPercentage from "./DetailsResultsByHitPercentage";
import DetailsResultsByHitCompetency from "./DetailsResultsByHitCompetency";
// import DetailsResultsByHitCompetencyGender from "./DetailsResultsByHitCompetencyGender";
import DetailsResultsSummary from "./DetailsResultsSummary";
import DetailsResultsHitByThematicAxis from "./DetailsResultsHitByThematicAxis";
import DetailsResultsHitLevelByThematicAxis from "./DetailsResultsHitLevelByThematicAxis";
// import DetailsResultsHitLevelByThematicAxisGender from "./DetailsResultsHitLevelByThematicAxisGender";
import DetailsResultsHitByStudent from "./DetailsResultsHitByStudent";
import DetailsResultsHitByUserAwnser from "./DetailsResultsHitByUserAwnser";

import {
  getReportTitle,
  getReportSummary,
  getReportHitsByCompetency,
  getReportByThematicAxis,
  getReportByStudents,
  generateSpreadSheetInforme,
} from "../../store/results";

import { parse } from "../../lib/reportDocumentHelpers";

import { exportXLSX } from "./excelHelper";

import "./Details.scss";

import detailsMessages from "./detailsMessages";
import resultsMessages from "./messages";
const messages = { ...detailsMessages, ...resultsMessages };

class Details extends React.PureComponent {
  state = {
    showDetails: false,
    loading: true,
    details: null,
    reports: null,
    error: "",
    showGenerateSheet: "0",
  };

  async componentDidMount() {
    this.setState({ loading: true, details: null, reports: null, error: "" });
    setTimeout(() => {
      this.handleFetchData();
    }, 1000);
    if (!this.props.countries.length) {
      await this.props.getCountries();
    }
    this.props.countries.map(
      el =>
        el.idCountry.toString() === this.props.user.idCountry.toString() &&
        this.setState({ showGenerateSheet: el.simplificaReportsGenerateSheet }),
    );
  }

  componentWillUnmount() {
    this.setState({ loading: true, details: null, reports: null, error: "" });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idSchedule !== this.props.idSchedule) {
      this.handleFetchData();
    }

    if (prevProps.reportSpreadSheet.studentDetail !== this.props.reportSpreadSheet.studentDetail) {
      this.downloadSpreadsheet();
    }
  }

  handleFetchData = async () => {
    const { idSchedule } = this.props;
    this.setState({ loading: true, details: null, reports: null });
    return await Promise.all([
      this.props.getReportTitle({ idSchedule }),
      this.props.getReportSummary({ idSchedule }),
      this.props.getReportHitsByCompetency({ idSchedule }),
      this.props.getReportByThematicAxis({ idSchedule }),
      this.props.getReportByStudents({ idSchedule }),
    ]).then(() => {
      const { details, reports } = this.props;
      this.setState({ loading: false, details, reports });
    });
  };

  report = React.createRef();

  renderPDF = () => {
    const sections = parse(this.report.current);
    const title = this.props.intl.formatMessage(messages.detailsReportTitle);
    const subtitle = this.props.intl.formatMessage(messages.detailsReportSchool);
    const label = this.props.intl.formatMessage(messages.detailsReportSchoolNameLabel);
    return (
      <ReportPDF
        sections={sections}
        title={title}
        subtitle={subtitle}
        label={label}
        {...this.props.details.title}
      />
    );
  };

  downloadSpreadsheet() {
    const { idSchedule, reportSpreadSheet, user } = this.props;
    const studentDetail = reportSpreadSheet.studentDetail;
    if (studentDetail) {
      let hasGradeCalculated = false;
      let hasScoreCalculated = false;

      // SIMPLIFICA-2553
      let hasLikertQuestion = false;

      // const studentAttempt = studentDetail ?
      // studentDetail.map(
      //   item => item.attemptsStudent.filter(
      //     item => item.version.indexOf('1') > -1)
      //   ) : [];

      const studentAttempt = studentDetail ? studentDetail.map(item => item.attemptsStudent) : [];

      studentDetail.map(item =>
        item.gradeCalculated ? (hasGradeCalculated = true) : (hasGradeCalculated = false),
      );
      studentDetail.map(item =>
        item.scoreCalculated ? (hasScoreCalculated = true) : (hasScoreCalculated = false),
      );

      if (studentAttempt[0].length > 0) {
        const auxAttempt = [...Array(studentAttempt[0].length)].map((x, i) => i + 1);
        const auxAttemptColors = studentAttempt[0].map(item => item.idType);
        let totalCorrect = Object.values(reportSpreadSheet.TotalCorrect);
        let letterAux = studentDetail.map(item => item.levelName);
        let letter = "";
        letterAux[0].split(" ").map(item => (item.length === 1 ? (letter = item) : ""));

        /* Questions Aux Tab */
        const questionsTables = reportSpreadSheet.questionsTable;
        const versionIndex = Object.keys(questionsTables).length;
        let questions = [];
        let number = 8;
        let numberAux = [];
        let versionArr = [];
        let arrQuestion = [];
        let arr = [];
        const letterVersion = ["", "A", "B", "C"];
        let linesEmpty = 2;

        questionsTables[1].forEach((q, i) => {
          arr = [];
          arr.push([q.name, "", "", "", ""]);

          if (!hasLikertQuestion && q.idType === "5") {
            hasLikertQuestion = true
          } 
          
          if (q.idType === "3") {
            arr.push([this.props.intl.formatMessage(messages.openQuestion), "", "", "", ""]);
          } else {
            arr.push([
              "",
              "",
              this.props.intl.formatMessage(messages.frequency),
              this.props.intl.formatMessage(messages.percentage),
              "",
            ]);

            q.rows.forEach((r, j) => {
              if (r.label !== "valids" && r.label !== "omitted" && r.label !== "total") {
                arr.push(["", r.label, r.frequency, r.percentage, ""]);
              }
            });

            if (arr.length < 7) {
              number = 7 - arr.length;
              numberAux = [...Array(number)].map(x => 0);

              numberAux.forEach(el => {
                arr.push(["", "", "", "", ""]);
                linesEmpty++;
              });
            }

            q.rows.forEach((r, j) => {
              if (r.label === "total") {
                arr.push(["", r.label, r.frequency, r.percentage, ""]);
              }
              if (r.label !== "valids" && r.label === "omitted") {
                arr.push([
                  "",
                  this.props.intl.formatMessage(messages.omitted),
                  r.frequency,
                  r.percentage,
                  "",
                ]);
              }
              if (r.label === "valids") {
                arr.push([
                  this.props.intl.formatMessage(messages.valid),
                  this.props.intl.formatMessage(messages.total),
                  r.frequency,
                  r.percentage,
                  "",
                ]);
              }
            });

            if (arr.length < 10) {
              number = 10 - arr.length;
              numberAux = [...Array(number)].map(x => 0);
              numberAux.forEach(el => {
                arr.push(["", "", "", "", ""]);
                linesEmpty++;
              });
            }
          }

          arr.push([""]);
          arr.forEach(a => arrQuestion.push(a));
        });

        for (let index = 1; index <= versionIndex; index++) {
          versionArr.push(
            this.props.intl.formatMessage(messages.version) + ` ${letterVersion[index]}`,
            "",
            "",
            "",
            "",
          );

          if (index > 1 && questionsTables[index]) {
            // eslint-disable-next-line no-loop-func
            questionsTables[index].forEach((q, i) => {
              arrQuestion[i * 11].push(q.name, "", "", "", "");
              if (q.idType === "3") {
                arrQuestion[i * 11 + 1].push(
                  this.props.intl.formatMessage(messages.openQuestion),
                  "",
                  "",
                );
              } else {
                arrQuestion[i * 11 + 1].push(
                  "",
                  "",
                  this.props.intl.formatMessage(messages.frequency),
                  this.props.intl.formatMessage(messages.percentage),
                  "",
                );
                q.rows.forEach((r, j) => {
                  if (r.label !== "valids" && r.label !== "omitted" && r.label !== "total") {
                    arrQuestion[i * 11 + j + 2].push("", r.label, r.frequency, r.percentage, "");
                  }
                  if (r.label === "total") {
                    arrQuestion[i * 11 + j + linesEmpty].push(
                      "",
                      r.label,
                      r.frequency,
                      r.percentage,
                      "",
                    );
                  }
                  if (r.label !== "valids" && r.label === "omitted") {
                    arrQuestion[i * 11 + j + linesEmpty].push(
                      "",
                      this.props.intl.formatMessage(messages.omitted),
                      r.frequency,
                      r.percentage,
                      "",
                    );
                  }
                  if (r.label === "valids") {
                    if (arrQuestion[i * 11 + j + linesEmpty]) {
                      arrQuestion[i * 11 + j + linesEmpty].push(
                        this.props.intl.formatMessage(messages.valid),
                        this.props.intl.formatMessage(messages.total),
                        r.frequency,
                        r.percentage,
                        "",
                      );
                    }
                  }
                });
              }
            });
          }
        }

        arrQuestion.forEach(arr => {
          if (arr.length < 15) {
            number = 15 - arr.length;
            numberAux = [...Array(number)].map(x => 0);
            numberAux.forEach(el => {
              arr.push("");
            });
          }
        });

        questions.push(versionArr, [""]);

        arrQuestion.map(a => questions.push(a));

        let scoreCalculatedLabel = "";
        let gradeCalculatedLabel = "";

        let hasColumnIdentifier,
          hasColumnLogin,
          hasColumnGender,
          hasColumnGradeScaled,
          hasColumnNotQualified = false;

        studentDetail.forEach(item => {
          // Column B - identifier
          if (!hasColumnIdentifier && item.identifier && item.identifier !== "") {
            hasColumnIdentifier = true;
          }

          // Column D - login
          if (
            !hasColumnLogin &&
            item.login &&
            item.login !== "" &&
            user.simplificaReportsGenerateSheetHasLogin === 1
          ) {
            hasColumnLogin = true;
          }

          // Column E - Gender
          if (!hasColumnGender && item.gender && item.gender !== "") {
            hasColumnGender = true;
          }

          // Calculated score
          if (item.scoreCalculated && item.scoreCalculated.label) {
            scoreCalculatedLabel = item.scoreCalculated.label;
          }

          // Calculated grade
          if (item.gradeCalculated && item.gradeCalculated.label) {
            gradeCalculatedLabel = item.gradeCalculated.label;
          }

          // Scaled grade
          if (item.resultInfoTable && item.resultInfoTable.gradeScaled) {
            hasColumnGradeScaled = true;
          }

          // Has not qualified questions
          if (
            item.resultInfoTable &&
            item.resultInfoTable.notQualified &&
            item.resultInfoTable.notQualified > 0
          ) {
            hasColumnNotQualified = true;
          }
        });

        // Generate header
        let studentDetailHeader = [this.props.intl.formatMessage(messages.course)];

        if (hasColumnIdentifier) {
          studentDetailHeader.push(this.props.intl.formatMessage(messages.letter));
        }

        studentDetailHeader.push(this.props.intl.formatMessage(messages.name));

        if (hasColumnLogin) {
          studentDetailHeader.push(this.props.intl.formatMessage(messages.studentID));
        }

        if (hasColumnGender) {
          studentDetailHeader.push(this.props.intl.formatMessage(messages.gender));
        }

        if (user.simplificaReportsGenerateSheetHasLogin === 1) {
          studentDetailHeader.push(this.props.intl.formatMessage(messages.version));
        }

        if (hasScoreCalculated) {
          studentDetailHeader.push(scoreCalculatedLabel);
        }

        if (hasGradeCalculated) {
          studentDetailHeader.push(gradeCalculatedLabel);
        }

        // Result info table header
        let resultInfoTableHeader = [
          // this.props.intl.formatMessage(messages.reportsLabelGrade),
          ...(!hasLikertQuestion ? [this.props.intl.formatMessage(messages.reportsLabelGrade)] : [this.props.intl.formatMessage(messages.relativeScore)]),
          this.props.intl.formatMessage(messages.reportsLabelLevel),
          // this.props.intl.formatMessage(messages.reportsLabelHits),
          ...(!hasLikertQuestion ? [this.props.intl.formatMessage(messages.reportsLabelHits)] : [this.props.intl.formatMessage(messages.hitLevelLabelAnswered)]),
          // this.props.intl.formatMessage(messages.reportsLabelErrors),
          ...(!hasLikertQuestion ? [this.props.intl.formatMessage(messages.reportsLabelErrors)] : []),
          this.props.intl.formatMessage(messages.reportsLabelOmitted),
        ];

        if (hasColumnNotQualified) {
          resultInfoTableHeader.push(
            this.props.intl.formatMessage(messages.reportsLabelNotQualified),
          );
        }

        // hasColumnNotQualified
        if (hasColumnGradeScaled) {
          resultInfoTableHeader.splice(
            1,
            0,
            (!hasLikertQuestion ? this.props.intl.formatMessage(messages.reportsLabelGradeScaled) : this.props.intl.formatMessage(messages.absolutScore)),
          );
        }

        // studentDetailContent
        let studentDetailContent = [];
        let dataResultContent = [];

        studentDetail.forEach((item, index) => {
          let studentDetailItem = [];
          let dataResultItem = [];

          studentDetailItem.push(item.levelName);
          dataResultItem.push(item.levelName);

          if (hasColumnIdentifier) {
            studentDetailItem.push(item.identifier);
            dataResultItem.push(item.identifier);
          }

          studentDetailItem.push(item.studentName);
          dataResultItem.push(item.studentName);

          if (hasColumnLogin) {
            studentDetailItem.push(item.studentID);
            dataResultItem.push(item.studentID);
          }

          if (hasColumnGender) {
            studentDetailItem.push(item.gender);
            dataResultItem.push(item.gender);
          }

          if (user.simplificaReportsGenerateSheetHasLogin === 1) {
            studentDetailItem.push(item.version);
            dataResultItem.push(item.version);
          }

          if (hasScoreCalculated && item.scoreCalculated) {
            studentDetailItem.push(parseInt(item.scoreCalculated.result));
            dataResultItem.push(parseInt(item.scoreCalculated.result));
          }

          if (hasGradeCalculated && item.gradeCalculated) {
            studentDetailItem.push(parseFloat(item.gradeCalculated.result).toFixed(1));
            dataResultItem.push(parseFloat(item.gradeCalculated.result).toFixed(1));
          }

          // Student attempt answers
          let studentAttemptAnswer = studentAttempt[index].map(item =>
            item.idType === "3"
              ? item.answerGrade
              : item.hitQuestion === "True"
              ? true
              : item.answer,
          );
          studentAttemptAnswer.forEach(answer => {
            studentDetailItem.push(answer);
          });

          // Student answer position
          let studentAnswerPosition = studentAttempt[index].map(item =>
            item.idType === "5"
              ? item.alternativeGrade 
              : item.idType === "3" 
              ? item.answerGrade 
              : item.positionAnswered,
          );
          studentAnswerPosition.forEach(answer => {
            dataResultItem.push(answer);
          });

          // Result info table (hits, errors, omitted and axis grades)
          studentDetailItem.push(item.resultInfoTable.grade);
          dataResultItem.push(item.resultInfoTable.grade);

          if (hasColumnGradeScaled) {
            studentDetailItem.push(item.resultInfoTable.gradeScaled);
            dataResultItem.push(item.resultInfoTable.gradeScaled);
          }

          if (item.resultInfoTable.level === "high") {
            if (!hasLikertQuestion) {
              studentDetailItem.push(this.props.intl.formatMessage(messages.hitLevelCategory_high));
              dataResultItem.push(this.props.intl.formatMessage(messages.hitLevelCategory_high));
            } else {
              studentDetailItem.push(this.props.intl.formatMessage(messages.hitLevelCategory_high_likert));
              dataResultItem.push(this.props.intl.formatMessage(messages.hitLevelCategory_high_likert));
            }
          } else if (item.resultInfoTable.level === "medium-high") {
            if (!hasLikertQuestion) {
              studentDetailItem.push(this.props.intl.formatMessage(messages["hitLevelCategory_medium-high"]));
              dataResultItem.push(this.props.intl.formatMessage(messages["hitLevelCategory_medium-high"]));
            } else {
              studentDetailItem.push(this.props.intl.formatMessage(messages["hitLevelCategory_medium-high_likert"]));
              dataResultItem.push(this.props.intl.formatMessage(messages["hitLevelCategory_medium-high_likert"]));
            }
          } else if (item.resultInfoTable.level === "low-medium") {
            if (!hasLikertQuestion) {
              studentDetailItem.push(this.props.intl.formatMessage(messages["hitLevelCategory_low-medium"]));
              dataResultItem.push(this.props.intl.formatMessage(messages["hitLevelCategory_low-medium"]));
            } else {
              studentDetailItem.push(this.props.intl.formatMessage(messages["hitLevelCategory_low-medium_likert"]));
              dataResultItem.push(this.props.intl.formatMessage(messages["hitLevelCategory_low-medium_likert"]));
            }
          } else if (item.resultInfoTable.level === "low") {
            if (!hasLikertQuestion) {
              studentDetailItem.push(this.props.intl.formatMessage(messages.hitLevelCategory_low));
              dataResultItem.push(this.props.intl.formatMessage(messages.hitLevelCategory_low));
            } else {
              studentDetailItem.push(this.props.intl.formatMessage(messages.hitLevelCategory_low_likert));
              dataResultItem.push(this.props.intl.formatMessage(messages.hitLevelCategory_low_likert));
            }
          }

          studentDetailItem.push(item.resultInfoTable.hits);
          if (!hasLikertQuestion) {
            studentDetailItem.push(item.resultInfoTable.errors);
          }
          studentDetailItem.push(item.resultInfoTable.omitted);

          if (hasColumnNotQualified) {
            studentDetailItem.push(item.resultInfoTable.notQualified);
          }

          dataResultItem.push(item.resultInfoTable.hits);
          if (!hasLikertQuestion) {
            dataResultItem.push(item.resultInfoTable.errors);
          }
          dataResultItem.push(item.resultInfoTable.omitted);

          if (hasColumnNotQualified) {
            dataResultItem.push(item.resultInfoTable.notQualified);
          }

          // Result info table axis header names
          item.resultInfoTable.axis.forEach(item => {
            if (index === 0) {
              resultInfoTableHeader.push(item.name);
            }

            studentDetailItem.push(item.grade + "%");
            dataResultItem.push(item.grade + "%");
          });

          // Add to studentDetailContent array
          studentDetailContent.push(studentDetailItem);

          // Add to dataResultContent array
          dataResultContent.push(dataResultItem);
        });

        // Student detail result array
        let studentDetailResult = [];

        studentDetailResult.push("");

        if (hasColumnIdentifier) {
          studentDetailResult.push("");
        }

        if (user.simplificaReportsGenerateSheetHasLogin === 1) {
          studentDetailResult.push("");
        }

        if (hasColumnLogin) {
          studentDetailResult.push("");
        }

        if (hasColumnGender) {
          studentDetailResult.push("");
        }

        if (hasScoreCalculated) {
          studentDetailResult.push("");
        }

        if (hasGradeCalculated) {
          studentDetailResult.push("");
        }

        // Total hits percentage
        studentDetailResult.push(
          !hasLikertQuestion 
            ? this.props.intl.formatMessage(messages.correct)
            : this.props.intl.formatMessage(messages.hitLevelLabelAnswered)
        );
        let total = totalCorrect.map(item => item);
        total.forEach(answer => {
          studentDetailResult.push(answer);
        });

        // End student detail result array

        /* Arrays to xlsx file */
        const dataStudent = [studentDetailHeader.concat(auxAttempt).concat(resultInfoTableHeader)]
          .concat(
            // studentDetail.map((item, index) => [
            //   item.levelName,
            //   // item.identifier ? item.identifier : "",
            //   hasColumnIdentifier && item.identifier,
            //   item.studentName,
            //   item.studentID ? item.studentID : "",
            //   // item.studentID && item.studentID !== '',
            //   item.gender ? item.gender : "",
            //   // item.gender && item.gender !== '',
            //   item.version ? item.version : "",
            //   item.scoreCalculated && parseInt(item.scoreCalculated.result),
            //   item.gradeCalculated && parseFloat(item.gradeCalculated.result).toFixed(1),
            // ]
            studentDetailContent,
            // .concat(studentAttempt[index].map(item => item.hitQuestion === "True" ? true : item.answer )))
          )
          .concat(
            [studentDetailResult],
            // [["",
            //   "",
            //   "",
            //   "",
            //   "",
            //   studentDetail.map((item, index) => item.gradeCalculated && ""),
            //   studentDetail.map((item, index) => item.scoreCalculated && ""),
            //   this.props.intl.formatMessage(messages.correct)].concat(totalCorrect.map(item => item))]
          );

        const dataQuestion = [
          [this.props.intl.formatMessage(messages.tableOfFrecuency)],
          [""],
        ].concat(questions.map(item => item));

        const dataResult = [
          // [ this.props.intl.formatMessage(messages.course),
          //   this.props.intl.formatMessage(messages.letter),
          //   this.props.intl.formatMessage(messages.name),
          //   this.props.intl.formatMessage(messages.studentID),
          //   this.props.intl.formatMessage(messages.gender),
          //   this.props.intl.formatMessage(messages.version),
          //   studentDetail.map((item, index) => item.scoreCalculated && item.scoreCalculated.label),
          //   studentDetail.map((item, index) => item.gradeCalculated && item.gradeCalculated.label)
          // ]
          studentDetailHeader.concat(auxAttempt).concat(resultInfoTableHeader),
        ]
          .concat(
            // studentDetail.map((item, index) => [
            //   item.levelName,
            //   item.identifier ? item.identifier : "",
            //   item.studentName,
            //   item.studentID ? item.studentID : "",
            //   item.gender ? item.gender : "",
            //   item.version ? item.version : "",
            //   item.scoreCalculated && parseInt(item.scoreCalculated.result),
            //   item.gradeCalculated && parseFloat(item.gradeCalculated.result).toFixed(1),
            // ].concat(studentAttempt[index].map(item => item.positionAnswered)))
            dataResultContent,
          )
          .concat(
            [studentDetailResult],
            // [["",
            // "",
            // "",
            // "",
            // "",
            // studentDetail.map((item, index) => item.gradeCalculated && ""),
            // studentDetail.map((item, index) => item.scoreCalculated && ""),
            // this.props.intl.formatMessage(messages.correct)].concat(totalCorrect.map(item => item))]
          );

        exportXLSX(
          dataStudent,
          dataQuestion,
          dataResult,
          this.props.intl.formatMessage(messages.sheet),
          this.props.intl.formatMessage(messages.sheet) +
            this.props.match.params.scheduleId +
            ".xlsx",
          this.props.intl,
          versionIndex,
          hasGradeCalculated,
          hasScoreCalculated,
          hasColumnIdentifier,
          hasColumnLogin,
          hasColumnGender,
          user.simplificaReportsGenerateSheetHasLogin,
          auxAttemptColors,
        );

        this.setState({ loading: false });
      } else {
        this.setState({
          error: "errCode23",
        });
      }
    } else {
      this.setState({
        error: "errCode" + this.props.reportSpreadSheetErrorCode,
      });
    }
  }

  handleSpreadsheet = async () => {
    const { idSchedule, reportSpreadSheet, user } = this.props;

    this.setState({ loading: true });
    await this.props.generateSpreadSheetInforme({ idSchedule });
  };

  render() {
    const { details, reports, error, showGenerateSheet } = this.state;
    const { title, summary } = details || {};

    const { hasLikertQuestion } = (details && details.title) || {};

    let { competency, thematicAxis } = details || {};

    if (!competency) {
      competency = {
        hitsByCompetency: [],
        hitsByCompetencyGender: [],
      };
    }

    if (!thematicAxis) {
      thematicAxis = {
        hitsByThematicAxis: [],
        hitsByThematicAxisGender: [],
        hitsLevelByThematicAxis: [],
      };
    }

    return (
      <div className="report-details">
        <Loader show={this.state.loading} />
        {error === "errCode23" && (
          <Alert>
            <FormattedMessage {...messages.errCode23} />
          </Alert>
        )}
        {error === "errCode22" && (
          <Alert>
            <FormattedMessage {...messages.errCode22} />
          </Alert>
        )}
        <div className="actions">
          <FormattedMessage {...messages.reportFilename}>
            {filename => (
              <Fragment>
                <PDFDownloadLink
                  renderPDF={this.renderPDF}
                  onClick={() => this.setState({ loading: true })}
                  onComplete={() => this.setState({ loading: false })}
                  filename={`${filename}_${this.props.idSchedule}.pdf`}
                >
                  <FormattedMessage {...messages.downloadPdf}>
                    {txt => <Button>{txt}</Button>}
                  </FormattedMessage>
                </PDFDownloadLink>
                {showGenerateSheet.toString() === "1" && (
                  <FormattedMessage {...messages.generateSpreadsheet}>
                    {txt => (
                      <Button
                        onClick={this.handleSpreadsheet}
                        style={{ minWidth: "15%", height: "50px", marginLeft: "20px" }}
                      >
                        {txt}
                      </Button>
                    )}
                  </FormattedMessage>
                )}
              </Fragment>
            )}
          </FormattedMessage>
        </div>
        <div>
          <DetailsHeader {...title} />
          <div ref={this.report}>
            {summary && (
              <React.Fragment>
                {summary.resultHitLevel && (
                  <DetailsResultsByHitLevel order="1" data={summary.resultHitLevel} hasLikertQuestion={hasLikertQuestion} />
                )}
                {summary.hitLevel && <DetailsHitLevel order="2" data={summary.hitLevel} hasLikertQuestion={hasLikertQuestion} />}
              </React.Fragment>
            )}
            {thematicAxis &&
              thematicAxis.hitsByThematicAxis &&
              thematicAxis.hitsByThematicAxis.length > 0 && (
                <DetailsResultsHitByThematicAxis
                  order="3"
                  data={thematicAxis.hitsByThematicAxis || null}
                  thematicAxis={thematicAxis}
                />
              )}
            {competency && competency.hitsByCompetency && (
              <DetailsResultsByHitCompetency order="4" data={competency.hitsByCompetency || null} hasLikertQuestion={hasLikertQuestion} />
            )}
            {reports && <DetailsResultsHitByStudent order="5" reports={reports} hasLikertQuestion={hasLikertQuestion} />}
            {thematicAxis && thematicAxis.hitsLevelByThematicAxis && (
              <DetailsResultsHitLevelByThematicAxis
                order="6"
                data={thematicAxis.hitsLevelByThematicAxis.data || null}
                title={thematicAxis.hitsLevelByThematicAxis.title}
              />
            )}
            {reports && <DetailsResultsHitByUserAwnser order="7" reports={reports} />}
            {summary && summary.questionsTable && summary.questionsTable.length > 0 && (
              <DetailsResultsSummary
                order="8"
                data={summary.questionsTable}
                locale={this.props.locale}
              />
            )}
            {/* {summary && <DetailsResultsByHitPercentage data={summary.hitLevelByGender} />} */}
            {/* <DetailsResultsByHitCompetencyGender data={competency.hitsByCompetencyGender || []} /> */}
            {/* {thematicAxis && thematicAxis.hitsByThematicAxisGender && (
              <DetailsResultsHitLevelByThematicAxisGender data={thematicAxis.hitsByThematicAxisGender || null} />
            )} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ results, language, app, login }, { match }) => {
  return {
    ...results,
    ...app,
    locale: language.locale,
    idSchedule: match.params.scheduleId,
    countries: login.countries,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getReportTitle,
    getReportSummary,
    getReportHitsByCompetency,
    getReportByThematicAxis,
    getReportByStudents,
    generateSpreadSheetInforme,
    getCountries,
  }),
  injectIntl,
)(Details);
